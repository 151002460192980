import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/383108529">
    <SEO title="Decision and Defeat - Judges" />
  </Layout>
)

export default SermonPost
